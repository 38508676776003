export const BARS = 'https://storage.googleapis.com/pp_img/policies/icons/bars.png'
export const DESKTOP = 'https://storage.googleapis.com/pp_img/policies/icons/desktop.png'
export const IMPROVEMENT = 'https://storage.googleapis.com/pp_img/policies/icons/improvement.png'
export const LOCK = 'https://storage.googleapis.com/pp_img/policies/icons/lock.png'
export const TERMS_CONDITIONS = 'https://storage.googleapis.com/pp_img/policies/icons/terms-and-conditions.png'
export const GROWTH = 'https://storage.googleapis.com/pp_img/policies/icons/growth.png'
export const KTP = 'https://storage.googleapis.com/pp_img/policies/icons/ktp-image.svg'
export const LICENCE = 'https://storage.googleapis.com/pp_img/policies/icons/lic-image.png'
export const CHAT = 'https://storage.googleapis.com/pp-static-testing/chat-icon%403x.png'
export const SEND = 'https://storage.googleapis.com/pp-static-testing/send-icon%403x.png'
export const COMMENT = 'https://storage.googleapis.com/pp-static-testing/comment-icon%403x.png'
export const SEND_WHATSAPP_BTN = 'https://storage.googleapis.com/pp_img/policies/icons/send_whatsapp_otp_btn.png'
export const RECIVE_WHATSAPP_OTP = 'https://storage.googleapis.com/pp_img/policies/icons/recive_whatsapp_otp.jpg'
export const SEND_WHATSAPP_OTP = 'https://storage.googleapis.com/pp_img/policies/icons/send_whatsapp_otp.jpg'
export const UPLOAD_ICON = 'https://storage.googleapis.com/pp_img/policies/icons/upload-ic-copy%403x.png'
export const IMG_UPLOAD_ICON = 'https://storage.googleapis.com/pp-static/upload-icon.png'
export const RUKITA_ENGINE_SAMPLE = 'https://storage.googleapis.com/pp-static/rukita-car-sample.png'
export const STNK_FRONT_SIDE = 'https://storage.googleapis.com/pp-static/stnk-front.png'
export const STNK_BACK_SIDE = 'https://storage.googleapis.com/pp-static/stnk-back.png'
export const DASHBOARD_ICON = 'https://storage.googleapis.com/pp_img/policies/main/home-icon-dashboard.svg'
export const DIGITAL_ICON = 'https://storage.googleapis.com/pp_img/policies/main/home-icon-digital.svg'
export const LOCK_ICON = 'https://storage.googleapis.com/pp_img/policies/main/home-icon-lock.svg'

// IMEI images
export const IMEI_STEP_1 = 'https://storage.googleapis.com/pp_img/policies/icons/imei-step-1.png'
export const IMEI_STEP_2 = 'https://storage.googleapis.com/pp_img/policies/icons/imei-step-2.png'

// Flags images
export const UNITED_STATES = 'https://storage.googleapis.com/pp_img/policies/flags/united-states.svg'
export const INDONESIA = 'https://storage.googleapis.com/pp_img/policies/flags/indonesia.svg'
export const THAILAND = 'https://storage.googleapis.com/pp_img/policies/flags/thailand.svg'
export const VIETNAM = 'https://storage.googleapis.com/pp_img/policies/flags/vietnam.svg'

// GIF
export const KTP_MOBILE_GIF = 'https://storage.googleapis.com/pp_img/policies/gif/mobileKtp.gif'
export const KTP_DESKTOP_GIF = 'https://storage.googleapis.com/pp_img/policies/gif/desktopKtp.gif'
export const IMEI_DESKTOP_GIF = 'https://storage.googleapis.com/pp_img/policies/gif/imei.gif'
export const IMEI_ENG_GIF = 'https://storage.googleapis.com/pp_img/policies/gif/imei_en.gif'

// Claim Details
export const VERIFICATION_ICON = 'https://storage.googleapis.com/pp_img/policies/icons/verification-2px.png'
export const REUPLOAD = 'https://storage.googleapis.com/pp_img/policies/icons/reupload.png'
export const UPLOAD_DOC = 'https://storage.googleapis.com/pp_img/policies/icons/upload-doc.png'
export const TIMER = 'https://storage.googleapis.com/pp_img/policies/icons/timer.png'
export const TIMER_WHITE = 'https://storage.googleapis.com/pp_img/policies/icons/timer-white.png'
export const MONEY = 'https://storage.googleapis.com/pp_img/policies/icons/money-icon.png'

// Endorsement
export const CLOSE_ICON = 'https://storage.googleapis.com/pp_img/policies/icons/close-icon.png'

// Home Page
export const HOME_BANNER = 'https://storage.googleapis.com/pp_img/policies/main/home-banner.svg'
export const MOBILE_HOME_BANNER = 'https://storage.googleapis.com/pp_img/policies/main/mobile-home-banner.svg'
export const HOME_BACKGROUND = 'https://storage.googleapis.com/pp_img/policies/main/home-background-cloud.svg'

// Policy Detail Page
export const ARROW_RIGHT_WHITE = 'https://storage.googleapis.com/pp_img/claim_portal/arrowRight-white.svg'
export const DOWNLOAD_BLUE = 'https://storage.googleapis.com/pp_img/claim_portal/download-blue.svg'
export const ARROW_UP = 'https://storage.googleapis.com/pp_img/claim_portal/ArrowUp.svg'
export const ARROW_DOWN = 'https://storage.googleapis.com/pp_img/claim_portal/ArrowDown.svg'
export const GROUP = 'https://storage.googleapis.com/pp_img/claim_portal/group-icon.svg'
export const POLIS_ID = 'https://storage.googleapis.com/pp_img/claim_portal/polis-id.svg'
export const PROFILE = 'https://storage.googleapis.com/pp_img/claim_portal/profile.svg'
export const KTP_ICON = 'https://storage.googleapis.com/pp_img/claim_portal/Credit%20Card.svg'
export const PHONE = 'https://storage.googleapis.com/pp_img/claim_portal/Call.svg'
export const CART = 'https://storage.googleapis.com/pp_img/claim_portal/Cart.svg'
export const PACKAGE = 'https://storage.googleapis.com/pp_img/claim_portal/Package.svg'
export const TAG = 'https://storage.googleapis.com/pp_img/claim_portal/Tag.svg'
export const LIKES = 'https://storage.googleapis.com/pp_img/claim_portal/Likes.svg'
export const RECEIPT = 'https://storage.googleapis.com/pp_img/claim_portal/receipt.svg'
export const CALENDAR = 'https://storage.googleapis.com/pp_img/claim_portal/Calendar.svg'
export const END_PROTECTION = 'https://storage.googleapis.com/pp_img/claim_portal/end-protection.svg'
export const CLOCK = 'https://storage.googleapis.com/pp_img/claim_portal/Clock.svg'
